import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Axios from "axios";

class NewsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          news: [],
        }
      }

    async componentDidMount() {
        const url = `https://api.annyiavilag.hu/news`;

        const news = await fetch(url).then(response => response.json())
        this.setState({
          news,
        })
      }

    render() {
        const { news } = this.state

        return (
            <>
                <Layout>
                    <SEO title="Hírek ma, hírek online, mai hírek" />
                    <h1>Hírek</h1>

                    {news.length === 0 ? (
                        <p>Hírek betöltése...</p>
                    ) : (
                        <div className="cardify-content-wrapper">
                            {news.map(newitem => (
                                <a key={newitem.id} className="cardify-content-card news-card" href={newitem.url+"?reference=annyiavilag.hu"} target="_blank">
                                    <p>{newitem.title}</p>
                                    {newitem.urlToImage != "null" && /(http(s?)):\/\//i.test(newitem.urlToImage) ? (<img src={(newitem.urlToImage)}></img>) : (<p></p>)}
                                </a>
                            ))}
                        </div>
                    )}
                </Layout>
            </>
        )
    }
}

export default NewsPage